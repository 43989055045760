<template>
  <div class="develop-container">
    <div>
      <div><label>SAAS节点:</label></div>
      <span v-for="(name) in saasNodes" style="margin-right: 10px;">
        <input type="radio" :value="name" v-model="saasNode"/>{{ name }}
      </span>
    </div>
    <hr/>
    <div v-for="(req,index) in reqList" class="line-box">
      <input type="file" @change="onFileChange($event,req)" name="添加一个图片文件"/>
      <button @click="resetReqDataList(req)" :disabled="req.dataList.length==0">重置图片文件</button>
      <div>
        <pre>{{ JSON.stringify(req, null, 4) }}</pre>
      </div>
      <button @click="sendAiTask(req)" :disabled="req.dataList.length==0">发送aiTask</button>
      <hr/>
    </div>
    <div>
      <button @click="addAiTask(reqList)">增加一个aiTask</button>
    </div>
    <div class="line-box">
      <button @click="resetTraceId()">重置traceId</button>
    </div>
    <div class="line-box">
      <button @click="resetAll()">重置所有</button>
    </div>
    <hr/>
    <div class="line-box">
      <label>批量发送</label>
    </div>
    <div class="line-box">
      <input type="file" multiple @change="onFileChange2($event,reqList2)"/>
    </div>
    <div>
      <pre>{{ JSON.stringify(showReqList(reqList2), null, 4) }}</pre>
    </div>
    <div class="line-box">
      <button @click="sendAiTask2(reqList2)" :disabled="reqList2.length==0">批量发送aiTask</button>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
import {v4 as uuidv4} from 'uuid'

export default {
  data() {
    return {
      access: {accountId: 'uni_smart_demo', key: 'NEYXF6gnmgxQ5Nkuc8xWd5'},
      saasNode: 'staging-cn',
      saasNodes: ['staging-cn', 'staging-eu', 'staging-us'],

      traceId: uuidv4(),
      sn: uuidv4(),
      events: ['person', 'pet', 'vehicle', 'package'],

      reqList: [],
      // 批量发送
      reqList2: [],
    }
  },
  created() {
    this.addAiTask(this.reqList);
  },
  watch: {
    traceId(newVal, oldVal) {
      this.reqList.forEach(req => req.traceId = newVal)
    }
  },
  methods: {
    async createSock(reqList) {
      return new Promise((resolve, reject) => {
        const baseUrl = process.env.VUE_APP_BASE_API
        console.log('sockjs baseUrl:', baseUrl)
        // const sock = new SockJS(this.buildUrl('/aiDemo/sockjs'))
        const sock = new SockJS(baseUrl + '/aiDemo/sockjs')
        sock.onopen = () => {
          console.log('sock open!')
          // sock.send('test');
          resolve.apply(sock)
        }

        sock.onmessage = (e) => {
          /*
        {
          "event": "AI_TASK_RESULT", // String, event type enumeration
          "description": "Return AI analysis results", // String, text description
          "node": "staging-cn", // String, service node name
          "eventId": "", // String, event unique identifier
          "timestamp": 1234567890123, // long, 13 digits, timestamp when the event occurred
          "data": {
            "serialNumber": "745b5a28f1158e69597175e2495be932", // String, unique device identification
            "traceId": "00D90UJL0yEt0Vlg0MfVUFhw6GgyY", // unique video ID.
            "events": [{
              "eventObject": "PERSON", // AI event enumeration
              "eventType": "EXIST" // AI sub-event enumeration
            }],
            "imageUrl": "https://xyz.s3.cn-north-1.amazonaws.com.cn/xyz/abc.jpg", // Video screenshot
            "imageEncodedString": "", // Base64 encoded video screenshot data
            "imageName": "" // The name of the binary video screenshot
          }
        }
         */
          const result = JSON.parse(e.data).data
          console.log('sock message!', result)
          // sock.close();
          // const imageBase64 = result.data.imageEncodedString
          // result.data.imageEncodedString = '[show in picture]'
          // this.aiTaskResults.push({id: result.eventId, result, imageBase64})
          reqList.filter(req => req.traceId === result.data.traceId).forEach(req => {
            if (req.event === 'AI_SEGMENT_TASK_RESULT') {
              req.aiTaskSegmentResult.push(result.data.events);
            } else if (req.event === 'AI_TASK_RESULT') {
              req.aiTaskResult = result.data.events;
            }
          })
        }

        sock.onclose = () => {
          console.log('sock close!')
        }
      });

      return sock;
    },
    async sendAiTask2(reqList) {
      const socket = await this.createSock(reqList);
      for (const req in reqList) {
        socket.send()
        await this.sendAiTask(req);
      }
    },
    async onFileChange2(e, reqList) {
      console.log('onFileChange2 event files.length:', e.target.files.length)
      e.target.files.forEach(file => {
        const req = this.addAiTask(reqList);
        req.traceId = uuidv4()
        req.aiTaskSegmentResult = []
        this.addFile(req, file);
      });
      // const req = this.addAiTask(reqList);
      // req.traceId = uuidv4()
      // e.target.files.forEach(file => {
      //   this.addFile(req, file);
      // });
    },
    showReqList(reqList) {
      return reqList.map(req => {
        return {
          ...req,
          dataList: req.dataList.map(data => {
            return {
              ...data,
              encodedString: `[length=${data.encodedString.length}]`
            }
          }),
        }
      })
    },
    addAiTask(reqList) {
      reqList.forEach(req => req.isLast = 0)
      const req = {
        tenantId: process.env.VUE_APP_UNI_SMART_DEMO_TENANT_ID,
        sn: this.sn,
        traceId: this.traceId,
        events: this.events,
        order: reqList.length,
        isLast: 1,
        inputType: 1, // VIDEO(0), IMAGES(1), IMAGE(2)
        dataList: [],
        webhookUrl: process.env.VUE_APP_BASE_API + '/aiDemo/aiTaskResult',
        outEncodeType: 'base64'
      };
      reqList.push(req);
      return req;
    },
    resetAll() {
      this.resetTraceId();
      this.reqList = [];
      this.addAiTask(this.reqList);
    },
    resetTraceId() {
      this.traceId = uuidv4();
    },
    resetReqDataList(req) {
      req.dataList = []
    },
    async onFileChange(e, req) {
      console.log('onFileChange event files.length:', e.target.files.length)
      const files = e.target.files;
      if (!files || files.length === 0) {
        console.log('onFileChange files empty!')
        return;
      }
      this.addFile(req, files[0]);
    },
    async addFile(req, file) {
      const dataUrl = await new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onerror = reject;
        fileReader.onload = e => resolve(e.target.result);
        fileReader.readAsDataURL(file);
      });
      console.log('onFileChange dataUrl:', dataUrl.substr(0, 50), '...')
      const fileBase64 = dataUrl.split(',')[1]
      console.log('onFileChange fileBase64:', fileBase64.substr(0, 50), '...')
      req.dataList.push({
        encodeType: "base64",
        encodedString: fileBase64,
        order: req.dataList.length,
        name: file.name
      });
    },
    async sendAiTask(req) {

      const requestConfig = {
        // baseURL: this.consoleNode.baseUrl,
        url: '/aiDemo/sendAiTask',
        method: 'post',
        headers: {
          'Authorization': this.$store.getters.token,
          'Content-Type': 'application/json'
        },
        params: {
          // saasNode: (this.consoleNode.nodeName === 'prod-us' ? 'staging-us' : 'staging-cn')
          saasNode: this.saasNode,
          accessKey: this.access.key
        },
        data: req,
        timeout: 180 * 10000
      }
      console.log('sendAiTask requestConfig=', requestConfig)
      try {
        // const response = await axios.request(requestConfig)
        const response = await request(requestConfig)
        console.log('sendAiTask response:', response)
        const {code, message} = response
        if (code !== 0) {
          // this.$message.warning('code=', code, ',message=', message)
          return
        }
        // console.log('videoDataUrl:', this.videoDataUrl)
      } catch (e) {
        console.log('error:', e)
        // this.$message.warning('Failed to upload video file!')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style>
.line-box {
  margin-top: 5px;
}
</style>
